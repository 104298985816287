define([
    'angular',
    'vsRepeat',
    'datatables',
    './EfileController',
    './efileElements/EfileElementsController',
    './EfileDirectives',
    './EfileService',
    './EfileWorkflowController',
    './MigrationHistController',
], function () {
    'use strict';
    return angular.module('app.efile', ['app.EfileAdminController','app.EfileElementsController','app.efileDirectives', 'app.efileService', 'app.MigrationHistController', 'datatables', 'vs-repeat','app.EfileWorkflowControllers'])

    .config(['$stateProvider','$urlRouterProvider','GENERAL_CONFIG', function ($stateProvider, $urlRouterProvider,GENERAL_CONFIG) {
        $stateProvider
            .state('efile-data-push', {
                url: '/efile-data-push',
                templateUrl: 'app/components/efile/efile.html',
                data:{},
                access:""
            })
            .state('submissions-dasboard', {
                url: '/submissions-dasboard',
                templateUrl: 'app/components/efile/efileElements/submissionDashboard.html',
                data:{},
                access:"",
                controller:"SubmissionsDashboardController as ctrl"
            })
            .state('submissions-dasboard.list', {
                url: '/list',
                "templateUrl": "app/components/efile/efileElements/list-tpl.html",
                "controller": "SubmissionListCtrl as ctrl",
                type: 'grid',
                "api": {"get": GENERAL_CONFIG.base_url + "/loadGridJson?grid_id=5746&action_id=32096"},
                "noFilters": true,
                access: ""
            })
            .state('submissions-dasboard.submission', {
                url: '/submission/:id',
                templateUrl: 'app/components/efile/efileElements/submission.html',
                data:{},
                access:"",
                controller:"SubmissionController as ctrl",
                resolve:{
                    submissionLoaded:['$stateParams','EfileFactory','JsonObjectFactory','$q','$state',function($stateParams,EfileFactory,JsonObjectFactory,$q,$state) {
                        
                        var deferred = $q.defer();
                        

                        if(EfileFactory.selectedSubmission){
                            deferred.resolve(true);
                        }else{
                            JsonObjectFactory.getJSONObj(GENERAL_CONFIG.base_url + '/loadJsonObject?action_code=nvc2sx',
                                {submission_id:$stateParams.id}
                            ).then(function(response) {
                                if(!response.jsonObject || response.jsonObject.length === 0){
                                    $state.go("submissions-dasboard.list");
                                }
                                EfileFactory.selectedSubmission = response.jsonObject[0];
                                var tooltip = EfileFactory.selectedSubmission.TAX_PAYER_NAME;
                                let tab = {
                                    name: EfileFactory.selectedSubmission.TAX_YEAR + '-' + EfileFactory.selectedSubmission.TAX_PAYER,
                                    code: EfileFactory.selectedSubmission.SUBMISSION_ID,
                                    tooltip: tooltip,
                                    data:{
                                        rowData:EfileFactory.selectedSubmission
                                    }
                                };
                                EfileFactory.submissionTabs.push(tab);
                                deferred.resolve(true);
                            }).catch(function(e) {
                                $state.go("submissions-dasboard.list");
                            });
                        }


                        return deferred.promise;
                    }]
                }
            })
            .state('submissions-dasboard.submission.submit', {
                url: '/submit',
                templateUrl: 'app/components/efile/efileElements/submit.html',
                data:{},
                access:"",
                controller:"SubmitController as ctrl"
            })
            .state('submissions-dasboard.submission.submit.history', {
                url: '/',
                template: `
                    <div class="gt-dashboard-ex-body">
                        <div gt-datatable2 options="ctrl.options" ng-if="ctrl.options.data"></div>
                
                        <div ng-if="ctrl.loading" class="loader">
                            <i class="fa fa-spinner fa-spin fa-4x"></i>
                        </div>
                    </div>
                `,
                data:{},
                access:"",
                resolve:{
                    api_url:function(){
                        return '/loadJsonObject?action_code=81j7mv';
                    },
                    params:['EfileFactory','USER_SETTINGS',function(EfileFactory,USER_SETTINGS) {
                        return {
                            submission_id: EfileFactory.selectedSubmission.SUBMISSION_ID,
                            sso_id:USER_SETTINGS.user.sso_id
                        };
                    }],
                    columns:['EfileFactory',function(EfileFactory) {
                        return EfileFactory.submissionListColumns;
                    }],
                    title:function() {
                        return "IRS Submissions Status";
                    }
                },
                controller:"EfileDashboardPopupController as ctrl"
            })
            .state('submissions-dasboard.submission.dashboard', {
                url: '/dashboard',
                templateUrl: 'app/components/efile/efileElements/dashboard.html',
                data:{},
                access:"",
                controller:"EfileDashboardController as ctrl"
            })
            .state('submissions-dasboard.submission.pdfupload', {
                url: '/pdf-upload',
                templateUrl: 'app/components/efile/efileElements/pdf-upload.html',
                data:{},
                access:"",
                controller:"PDFUploadController as ctrl"
            })
            .state('submissions-dasboard.submission.data', {
                url: '/data',
                templateUrl: 'app/components/efile/efileElements/data.html',
                data:{},
                access:"",
                controller:"DataController as ctrl"
            })
            .state('submissions-dasboard.submission.errors', {
                url: '/errors',
                templateUrl: 'app/components/efile/efileElements/data/errors.html',
                data:{},
                access:"",
                controller:"DataErrorsController as ctrl"
            })
            .state('submissions-dasboard.submission.data.addEdit', {
                url: '/add-edit',
                templateUrl: 'app/components/efile/efileElements/data/addEdit.html',
                data:{},
                access:"",
                controller:"AddEditController as ctrl"
            })
            .state('submissions-dasboard.submission.xmlUploadKeyId', {
                url: '/xml-upload',
                templateUrl: 'app/components/efile/efileElements/xmlConsolUpload.html',
                data:{},
                access:"",
                controller:"XMLConsolUploadController as ctrl"
            })
            .state('submissions-dasboard.submission.overrides', {
                url: '/overrides',
                templateUrl: 'app/components/efile/efileElements/overrides/overrides-grid-list.html',
                data:{},
                access:"",
                type: 'grid',               
                 api:{"get":GENERAL_CONFIG.base_url + "/loadGridJson?grid_id=5886&action_id=32546"},
                controller:"overridesController as ctrl"
            })
            .state('submissions-dasboard.submission.deleteForm', {
                url: '/delete-form',
                templateUrl: 'app/components/efile/efileElements/delete-form.html',
                data:{},
                access:"",
                controller:"DeleteFormController as ctrl"
            })
            .state('submissions-dasboard.submission.lockUnlock', {
                url: '/lock-unlock',
                templateUrl: 'app/components/efile/efileElements/lock-unlock.html',
                data:{},
                access:"",
                controller:"LockUnlockController as ctrl"
            })
            .state('submissions-dasboard.submission.enabledDisabled', {
                url: '/enabled-disabled',
                templateUrl: 'app/components/efile/efileElements/enabled-disabled.html',
                data:{},
                access:"",
                controller:"EnabledDisabledController as ctrl"
            })
            .state('submissions-dasboard.submission.xmlSync', {
                url: '/xml-sync',
                templateUrl: 'app/components/efile/efileElements/xml-sync.html',
                data:{},
                access:"",
                controller:"XMLSyncController as ctrl"
            })
            .state('submissions-dasboard.submission.unlockValidation', {
                url: '/unlock-validation',
                templateUrl: 'app/components/efile/efileElements/unlock-validation.html',
                data:{},
                access:"",
                controller:"UnlockValidationController as ctrl"
            })
            .state('submissions-dasboard.submission.formElementsReport', {
                url: '/formelements-report',
                templateUrl: 'app/components/efile/efileElements/formelements-report.html',
                data:{},
                access:"",
                controller:"FormElementsReportController as ctrl"
            })
            .state('submissions-dasboard.submission.amendSupersededReturns', {
                url: '/amendsuperseded-returns',
                templateUrl: 'app/components/efile/efileElements/amendsuperseded-returns.html',
                data:{},
                access:"",
                controller:"AmendSupersededReturnsController as ctrl"
            })
            .state('submissions-dasboard.submission.efileScrubbing', {
                url: '/efile-scrubbing',
                templateUrl: 'app/components/efile/efileElements/efile-scrubbing.html',
                data:{},
                access:"",
                controller:"EfileScrubbingController as ctrl"
            })
            .state('submissions-dasboard.submission.keyXmlGen', {
                url: '/key-xml-gen',
                templateUrl: 'app/components/efile/efileElements/key-xml-gen.html',
                data:{},
                access:"",
                controller:"EfileKeyXmlGenController as ctrl"
            })
            .state('submissions-dasboard.submission.return-review',{
                url:'/return-review',
                templateUrl: 'app/components/efile/efileElements/return-review.html',
                data:{},
                access:"",
                controller:"EfileReturnReviewController as ctrl"
            })
            .state('submissions-dasboard.submission.return-review.validation',{
                url: '/errors',
                templateUrl: 'app/components/efile/efileElements/data/errors.html',
                data:{},
                access:"",
                controller:"DataErrorsController as ctrl"
            })
            .state('submissions-dasboard.submission.return-review.efile-diagnostics',{
                url: '/efile-diagnostics',
                templateUrl: 'app/components/efile/efileElements/grid-tpl.html',
                resolve:{
                    api_url: function(){
                        return '/loadGridJson?grid_id=5712&action_id=31996';
                    },
                    params: ['EfileFactory',function(EfileFactory){
                        return {
                            submission_id: EfileFactory.selectedSubmission.SUBMISSION_ID,
                        }
                    }],
                    role: function(){
                        return "ADMIN"
                    },
                    filters: function(){
                        return []
                    },
                    ctrlFn: ["workspaceFactory", function(workspaceFactory){
                        return function(callName, data){
                            const drilldown = JSON.parse(data.rowData.DRILLDOWN);

                            var _filter_json = [{ "filter_key": "1", "name": "tax_year", "value": data.rowData.TAX_YEAR.toString() }, { "filter_key": "2305", "name": "submission_id", "value": data.rowData.SUBMISSION_ID }];
                            workspaceFactory.loadLink("drillDown", "{}", drilldown.activity_key +'', drilldown.screen_key +'',_filter_json);

                        }
                    }],
                    statusCheckFn:function(){
                        return function(data){
                            return data.filter(d => d.DIAG_FAILED_CNT > 0).length === 0;
                        }
                    }
                },
                access:"",
                controller:"EfileCustomGrid as ctrl"
            })
            .state('submissions-dasboard.submission.return-review.efile-sno-dups',{
                url: '/efile-sno-dups',
                templateUrl: 'app/components/efile/efileElements/grid-tpl.html',
                resolve:{
                    api_url: function(){
                        return '/loadGridJson?grid_id=6169&action_id=33404';
                    },
                    params: ['EfileFactory','USER_SETTINGS', 'AlertService',function(EfileFactory,USER_SETTINGS, AlertService){
                        return {
                            sso_id: USER_SETTINGS.user.sso_id,
                            submission_id: EfileFactory.selectedSubmission.SUBMISSION_ID,
                        }
                    }],
                    role: function(){
                        return "ADMIN"
                    },
                    filters: function(){
                        return []
                    },
                    ctrlFn: ['EfileFactory', 'AlertService', '$uibModal', '$rootScope',function(EfileFactory, AlertService, $uibModal, $rootScope){
                        return function(callName, data){
                            console.log("dups disable", callName, data)
                            if (callName === "sub-sno-disable") {  
                                
                                let confirmation1 = $uibModal.open({
                                    templateUrl: 'app/templates/modals/confirm-action.html',
                                    windowClass: 'modal-warning',
                                    resolve:{
                                        message:function(){
                                            return `Disabled Elements can be reverted back from Efile Overrides Screen.
                                             Are you sure you want to disable the selected Elements?`
                                        }
                                    },
                                    controller: ['$scope', '$uibModalInstance','message',simpleConfirmationCtrl]
                                });
                                
                                confirmation1.result.then(function (selectedItem) {
                                    $rootScope.$broadcast("showLoader", true);

                                    EfileFactory.disableDuplicateSno(EfileFactory.selectedSubmission.SUBMISSION_ID,JSON.stringify(data.map(row => row.data))).then((res)=>{                                    
                                        if(res.callSuccess === "1"){
                                            AlertService.add("success", "Selected Elements were disabled Successfully" );                                        
                                            $rootScope.$broadcast('dataFilters:refreshGrid', {
                                                "refresh": true
                                            });
                                            
                                        }else{
                                            AlertService.add("error",res.errorMessage);
                                        }
                                        $rootScope.$broadcast("showLoader", false);
                                    })
                                 
                                    
                                  }, function () {
                                    
                                  });
                
                               
                            }

                        }
                    }],
                    statusCheckFn:function(){
                        return function(data){
                            return data.length === 0;
                        }
                    }
                },
                access:"",
                controller:"EfileCustomGrid as ctrl"
            })
            .state('submissions-dasboard.submission.return-review.submission-errors',{
                url: '/submission-errors',
                templateUrl: 'app/components/efile/efileElements/grid-tpl.html',
                resolve:{
                    api_url: function(){
                        return '/loadGridJson?grid_id=6171&action_id=33409';
                    },
                    params: ['EfileFactory','USER_SETTINGS',function(EfileFactory,USER_SETTINGS){
                        return {
                            sso_id: USER_SETTINGS.user.sso_id,
                            submission_id: EfileFactory.selectedSubmission.SUBMISSION_ID,
                        }
                    }],
                    role: function(){
                        return "ADMIN"
                    },
                    filters: function(){
                        return []
                    },
                    ctrlFn: function(){
                        return function(callName, data){
                        }
                    },
                    statusCheckFn:function(){
                        return function(data){
                            return data.length === 0;
                        }
                    }
                },
                access:"",
                controller:"EfileCustomGrid as ctrl"
            })
            .state('submissions-dasboard.submission.return-review.py-cy',{
                url: '/py-cy',
                templateUrl: 'app/components/efile/efileElements/grid-tpl.html',
                resolve:{
                    api_url: function(){
                        return '/loadGridJson?grid_id=6135&action_id=33332';
                    },
                    params: ['EfileFactory','USER_SETTINGS',function(EfileFactory,USER_SETTINGS){
                        return {
                            sso_id: USER_SETTINGS.user.sso_id,
                            submission_id: EfileFactory.selectedSubmission.SUBMISSION_ID,
                        }
                    }],
                    role: function(){
                        return "ADMIN"
                    },
                    filters: function(){
                        return []
                    },
                    ctrlFn: function(){
                        return function(callName, data){
                        }
                    },
                    statusCheckFn:function(){
                        return function(data){
                            return data.some(elem => elem.PREV_COUNT !== elem.CURRENT_COUNT);
                        }
                    }
                },
                access:"",
                controller:"EfileCustomGrid as ctrl"
            })
            //Efile Scrubbing before Validations
            .state('submissions-dasboard.submission.return-review.scrubbings',{
                url: '/efile-sub-scrubbings',
                templateUrl: 'app/components/efile/efileElements/grid-tpl.html',
                resolve:{
                    api_url: function(){
                        return '/loadGridJson?grid_id=6175&action_id=33421';
                    },
                    params: ['EfileFactory','USER_SETTINGS', 'AlertService',function(EfileFactory,USER_SETTINGS, AlertService){
                        return {
                            sso_id: USER_SETTINGS.user.sso_id,
                            submission_id: EfileFactory.selectedSubmission.SUBMISSION_ID,
                        }
                    }],
                    role: function(){
                        return "ADMIN"
                    },
                    filters: function(){
                        return []
                    },
                    ctrlFn: ['EfileFactory', 'AlertService', '$uibModal', '$rootScope',function(EfileFactory, AlertService, $uibModal, $rootScope){
                        return function(callName, data){
                            console.log("sub-req-validations", callName, data)
                            if (callName === "sub-req-validations") {  
                                
                                let confirmation1 = $uibModal.open({
                                    templateUrl: 'app/templates/modals/confirm-action.html',
                                    windowClass: 'modal-warning',
                                    resolve:{
                                        message:function(){
                                            return `Resolve all the Scrubbings for the selected Elements?
                                         NOTE: Only the Editable Elements will be updated from here. Rest can be updated using the Add/Edit Screen.`
                                        }
                                    },
                                    controller: ['$scope', '$uibModalInstance','message',simpleConfirmationCtrl]
                                });
                                
                                confirmation1.result.then(function (selectedItem) {
                                    $rootScope.$broadcast("showLoader", true);

                                    EfileFactory.resolveScrubbingsForSub(EfileFactory.selectedSubmission.SUBMISSION_ID,JSON.stringify(data.map(row => row.data))).then((res)=>{                                    
                                        if(res.callSuccess === "1"){
                                            AlertService.add("success", "Selected Elements are updated Successfully" );                                        
                                            $rootScope.$broadcast('dataFilters:refreshGrid', {
                                                "refresh": true
                                            });
                                            
                                        }else{
                                            AlertService.add("error",res.errorMessage);
                                        }
                                        $rootScope.$broadcast("showLoader", false);
                                    })
                                 
                                    
                                  }, function () {
                                    
                                  });
                
                               
                            }

                        }
                    }],
                    statusCheckFn:function(){
                        return function(data){
                            return data.length === 0;
                        }
                    }
                },
                access:"",
                controller:"EfileCustomGrid as ctrl"
            })
            .state('submissions-dasboard.submission.return-review.xml-vs-elements',{
                url: '/xml-vs-elements',
                templateUrl: 'app/components/efile/efileElements/grid-tpl.html',
                resolve:{
                    api_url: function(){
                        return '/loadGridJson?grid_id=6134&action_id=33331';
                    },
                    params: ['EfileFactory',function(EfileFactory){
                        return {
                            submission_id: EfileFactory.selectedSubmission.SUBMISSION_ID,
                        }
                    }],
                    role: function(){
                        return "ADMIN"
                    },
                    filters: function(){
                        return [
                            {
                                "filter_key" : "2344",
                                "param_name" : "irs_id",
                                "label" : "IRS Submissions",
                                "ui_type" : "df-select-single",
                                "multiple" : 1,
                                "data_url" : "loadJsonObject?action_code=j4k0an",
                                "data_type" : "load",
                                "label_name" : "LABEL",
                                "value_name" : "VALUE",
                                "default_value" : null,
                                "place_holder_text" : null,
                                "required" : 1,
                                "refresh_list" : "2305",
                                "static_values" : null,
                                "tool_tip_text" : null,
                                "validation" : null,
                                "blank_value" : 0,
                                "extra_options" : null,
                                "is_or_conditional_filter" : false,
                                "filter_priority" : 0,
                                "filter_map_key" : 0,
                                "match_global_filters" : 0,
                                "selected":[]
                              }
                        ]
                    },
                    ctrlFn: () => undefined,
                    statusCheckFn:function(){
                        return function(data){
                            return true;
                        }
                    }
                },
                access:"",
                controller:"EfileCustomGrid as ctrl"
            })
                
    }])
    function simpleConfirmationCtrl ($scope,$uibModalInstance,message) {                
        $scope.header = "Confirm";
        $scope.message = message;
        $scope.confirm = function () {
            $uibModalInstance.close();
        }
        $scope.cancel = function () {
            $uibModalInstance.dismiss();
        }

        $scope.$on('$destroy', function () {
            ////////////console.log("ngReally Destroyed");
        });
    }
});
